export class Constants {
    // public static MY_CRITIC_API_DOMAIN = 'https://localhost:8443'; // Use this for localhost testing
    // public static MY_CRITIC_API_DOMAIN = 'https://api-dev.mycritic.org:8443'; // Use this for Dev deployment
    // public static MY_CRITIC_API_DOMAIN = 'https://api.mycritic.org:8443';  // Use this for production deployment

    // public static API_GATEWAY_DOMAIN = 'https://localhost:4443/'; // Use this for Dev deployment
    // public static API_GATEWAY_DOMAIN = 'https://api-dev.mycritic.org:4443/'; // Use this for Dev deployment
    public static API_GATEWAY_DOMAIN = 'https://api.mycritic.org:4443/'; // Use this for Dev deployment

    public static MY_CRITIC_APP_NAME = 'mycritic';
    public static HOSPITAL_APP_NAME = 'hospital';
    public static SKIPLINE_APP_NAME = 'skipline';
    public static IDENTITY_APP_NAME = 'identity';
    public static HOSPITAL_APP_ID = 'HOSPITAL';
    public static MY_CRITIC_DOMAIN_API_PREFIX = `${Constants.API_GATEWAY_DOMAIN}${Constants.MY_CRITIC_APP_NAME}`;
    public static HOSPITAL_DOMAIN_API_PREFIX = `${Constants.API_GATEWAY_DOMAIN}${Constants.HOSPITAL_APP_NAME}`;
    public static SKIPLINE_DOMAIN_API_PREFIX = `${Constants.API_GATEWAY_DOMAIN}${Constants.SKIPLINE_APP_NAME}`;

    // api urls for Customer Feedback Service
    public static API_CUSTOMER_SAVE = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/customer/save`;
    public static API_GET_DEFAULT_OPINIONS = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/opinion/getAllDefaultOpinions`;
    public static API_CUSTOMER_FEEDBACK_SAVE = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/customerFeedback/save`;
    public static API_CUSTOMER_FEEDBACK_SAVE_ADHOC = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/customerFeedback/save/adhoc`;
    public static API_CUSTOMER_HOSPITAL_FEEDBACK_SAVE = `${Constants.HOSPITAL_DOMAIN_API_PREFIX}/feedback/save`;

    // api urls for Dashboard
    public static API_DASHBOARD_ALL_FEEDBACK_RESPONSES = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/dashboard/uspResponses`;
    public static API_DASHBOARD_ALL_OPINION_COUNTS = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/dashboard/getAllOpinionCounts`;
    public static API_DASHBOARD_COMMENTS = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/dashboard/getDashboardComments`;
    public static API_DASHBOARD_DAYS_OPINIONS_COUNTS = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/dashboard/getDaysOpinionCounts`;
    public static API_DASHBOARD_USP_GRAPH_RESPONSE = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/dashboard/uspGraphResponses`;

    // api urls for feedback options
    public static API_HOSPITAL_OPTIONS_SAVE = `${Constants.HOSPITAL_DOMAIN_API_PREFIX}/category/save`;
    public static API_HOSPITAL_OPTIONS_GET = `${Constants.HOSPITAL_DOMAIN_API_PREFIX}/category/getmetadata`;
    public static API_HOSPITAL_GET_ADMIN_WRT_ORG = `${Constants.HOSPITAL_DOMAIN_API_PREFIX}/admin/getAdminsWRTOrgId`;
    public static API_GET_CUSTOM_OPINIONS = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/opinion/getCustomOpinions`;
    public static API_GET_CUSTOM_OPINIONS_ADHOC = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/opinion/getCustomOpinions/adhoc`;
    public static API_CUSTOM_OPINIONS_SAVE = `${Constants.MY_CRITIC_DOMAIN_API_PREFIX}/opinion/saveCustomOpinions`;
    public static API_VERIFY_ADHOC = `${Constants.SKIPLINE_DOMAIN_API_PREFIX}/feedback/adhoc/verify`;


    // admin
    public static API_LOGIN = `${Constants.API_GATEWAY_DOMAIN}${Constants.IDENTITY_APP_NAME}/api/login`;
    public static API_GET_ADMIN_DETAILS = `${Constants.HOSPITAL_DOMAIN_API_PREFIX}/admin`;

    // navigation paths - appointments
    public static ADHOC_NAV = 'adhoc';
    public static ADHOC_NAV_URL = 'adhoc-feedback';
    public static ADHOC_NAV_TEST_URL = 'test/adhoc-feedback';
    // public static NAV_APPOINTMENTS:string = "/tabs/appointments";
    // public static NAV_QUEUE_DETAILS: string = `${Constants.NAV_APPOINTMENTS}/queue-details`;
    // public static NAV_EDIT_QUEUE:string = `${Constants.NAV_APPOINTMENTS}/edit-queue`;
    // public static NAV_ADD_APPOINT:string = `${Constants.NAV_APPOINTMENTS}/add-appointment`;
    // public static NAV_EDIT_APPOINT:string = `${Constants.NAV_APPOINTMENTS}/edit-appointment`;
    // public static NAV_CUSTOMER_HISTORY:string = `${Constants.NAV_APPOINTMENTS}/customer-history`;
    // public static NAV_CART_ITEMS:string = `${Constants.NAV_APPOINTMENTS}/cart-items`;
    // public static NAV_CART_MODEL:string = `${Constants.NAV_APPOINTMENTS}/cart-model`;

    // navigation paths - dashboard
    // public static NAV_DASHBOARD: string = "/tabs/dashboard";
    // public static NAV_FEEDBACKDASHBOARD: string = `${Constants.NAV_DASHBOARD}/feedback-dashboard`;


    // navigation paths - settings
    // public static NAV_SETTINGS: string = "/tabs/settings";
    // public static NAV_CHANGE_PASSWORD = `${Constants.NAV_SETTINGS}/changePassword`;
    // public static NAV_DYN_TEMPLATE = `${Constants.NAV_SETTINGS}/dynamicTemplate`;
    // public static NAV_SEND_PROMOS = `${Constants.NAV_SETTINGS}/sendPromotions`;
    // public static NAV_PRODUCTS = `${Constants.NAV_SETTINGS}/products`;
    // public static NAV_ADD_QUEUE = `${Constants.NAV_SETTINGS}/add-queue`;
    // public static NAV_FEEDBACK_OPTIONS = `${Constants.NAV_SETTINGS}/feedback-options`;


    // public static NAV_FORGOT_PASSWORD = "forgot-password";

    // // local storage
    public static USER_ACCESS = 'user-access';
    public static BEARER = 'Bearer ';
    // public static APPOINTMENT = 'appointment';
    // public static QUEUE = 'queue';
    // public static CUSTOMER_LIST = 'customerlist';
    // public static CUSTOMER_COUNT = 'noofcustomers';

}
